import React from "react"
import { Container, Row, Col, Image } from 'react-bootstrap'
import { BlueModel } from "./model"

const BoxModuleBlue = props => {
    return (
      <div className="section_pad_st">
        <Container>
          <Row className="margin_bot_35">
            <Col
            md={{ span: 6, offset: 0 }}
            >
            <h4 className="main_title main_b_color">{BlueModel.title}</h4>
            </Col>
          </Row>

          <Row className="section_shadow_st margin_0">
            <Col
              className="col-sm-5 pad_65 background_mask_blue"
            >
              
              <p className="main_text main_w_color">{BlueModel.text1}</p>
              <p className="main_text main_w_color">{BlueModel.text2}</p>
              
            </Col>
            <Col
              className="col-sm-7 align-self-center"
            >
              <Image src={BlueModel.image} alt="computer" className="inno_computer_st1 w-100"/>
            </Col>
          </Row>
        </Container>
        </div>
    )
  }

export default BoxModuleBlue
