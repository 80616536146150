import icon1 from "../../images/red_icon.png"
import icon2 from "../../images/orange_icon.png"
import icon3 from "../../images/yellow_icon.png"
import icon4 from "../../images/green_icon.png"
import imageCom from "../../images/Inno-computer.png"
import imageCall from "../../images/callout.jpg"

export const CardModel = {
    maintitle: "Why Jus-Pharma?",
    maindescription: "Jus-Pharma integrates, streamlines and secures every element of clinical trials to augment safety and efficiency.",
    content: [
        {
            icon: icon1,
            subtitle: "Expert Guidance",
            subtext: "Jus-Pharma optimizes communication amongst all parties from researchers, clinicians and patients to the C-suite executive team reporting to the board of directors.",
        },
        {
            icon: icon2,
            subtitle: "Data",
            subtext: "Jus-Pharma ensures that a clinical trial budget is judiciously attended while study management and controls become safer and more effective, advancing speed to market of new therapies.",
        },
        {
            icon: icon3,
            subtitle: "Results",
            subtext: "Such results allow best-in-class pharmaceutical researchers to continuously deploy capital to investigate and deliver to market the curative innovations of the future.",
        },
        {
            icon: icon4,
            subtitle: "Transparency",
            subtext: "Jus-Pharma delivers transparency to a formerly complex, disjointed ecosystem.",
        },
    ]
}
export const BoxModel = {
    title: "Advanced clinical studies that deliver the curative therapies of tomorrow",
    text: "Receive support from the most highly credentialed clinical, regulatory, business, legal and technical team assembled to serve the clinical trials industry. Optimize communication and budget. Speed to market life-changing and lifesaving interventions. Advance market share and profitability. Attract and retain investors. Utilize saved budget, increased revenue and profit to advance enterprise-wide clinical studies that deliver the curative therapies of tomorrow. Jus-Pharma is every desired innovation in the clinical trials process provided via desktop, laptop, smartphone (Apple approved Applications) and android device.",
    cta: "Learn More  >",
}

export const BlueModel = {
    title: "Innovative technology for everyone on your team",
    text1: "Tired of the multiple websites and never-ending red tape? Whether you are a pharmaceutical executive, researcher, or a clinical trial participant - all platform users benefit from real-time intelligence and a centralized storage location accessible at your fingertips.  You no longer have to remember multiple passwords or where you saved a consent form. Everyone benefits from the streamlined digital document management, multiple communication options in real time, and up-to-date data points. These state-of-the art features minimize time delays and maximize productivity - not to mention profitability! Subsequently, all parties enjoy a better experience throughout the entirety of the clinical trial process.",
    image: imageCom,
}
export const CallModel = {
    title: "For Investors",
    text: "Jus-Pharma is the only groundbreaking technology created and serviced by an expert team with over 100 years of combined professional experience that streamlines the clinical trials management process, preserving capital, mitigating risk, protecting enterprise stability and optimizing ROI. It empowers investors to maintain high confidence and ensures the enterprise remains an attractive buy.",
    cta: "Learn More  >",
    image: imageCall,
}