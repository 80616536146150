import React from "react"
import { Container, Row, Col, CardDeck, Card } from 'react-bootstrap'
import { CardModel } from "./model"

const CardsModule = pros => {
    return (
        <div className="section_pad_st">
            <Container>
                <Row className="margin_bot_35">
                    <Col
                    md={{ span: 6, offset: 3 }}
                    >
                    <h4 className="main_title main_b_color text-center">{CardModel.maintitle}</h4>
                    <p className="main_text main_b_color text-center">{CardModel.maindescription}</p>
                    </Col>
                </Row>
                <CardDeck className="">
                    {CardModel.content.map((item, i) => {
                        return (
                            <Card className="text-center jus_card_st" key={i}>
                                <Card.Img className="mx-auto max_w_140" variant="top" src={item.icon} alt={item.subtitle}/>
                                <Card.Body>
                                <Card.Title className="sub_title main_b_color">{item.subtitle}</Card.Title>
                                <Card.Text className="main_text main_b_color">{item.subtext}</Card.Text>
                                </Card.Body>
                            </Card>
                          )
                    })}
                </CardDeck>
            </Container>
        </div>
    )
}

export default CardsModule

