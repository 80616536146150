import React from "react"
import { Link } from 'gatsby'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { CallModel } from "./model"

const ImgModule = props => {
    return (
      <div className="callout_background">
        <Container>
            <Row>
              <Col
                className="col-sm-7 align-self-center"
              >
                <Image src={CallModel.image} alt="callout" className="w-100"/>

              </Col>
              <Col
                className="col-sm-5 align-self-center pad_65"
              >
                <h4 className="main_title main_b_color">{CallModel.title}</h4>
                <p className="main_text main_b_color">{CallModel.text}</p>
                <Link className="main_text" to="/contact">{CallModel.cta}</Link>
              </Col>
            </Row>
        </Container>
        </div>
    )
  }

export default ImgModule