
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import GenBtn from "../common/general-btn" 

const HeroBanner = () => {
  return (
    <div className="generalHerobanner home_hero hero_img_st">
      <div className="header_bg_overlay w-100 h-100 d-flex align-items-center">
        <Container
          className="h-100"
        >
            <Row className="h-100">
              <Col
                className="col-sm-7 align-self-center"
              >
                <h2 className="hero_title main_b_color txt_align_left">Radically Advancing Global Health</h2>
                <p className="hero_text main_b_color txt_align_left mb_55">by revolutionalizing the clinical trials process</p>
                <GenBtn label="Let's get Started" icon=">"/>
              </Col>
            </Row>
        </Container>
      </div>
    </div>
  )
}

export default HeroBanner
