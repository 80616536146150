import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from 'react-bootstrap'
import { BoxModel } from "./model"

const BoxModule = () => {
    return (
        <Container className="margin_bot_80">
            <div className="jus_box_st">
                <Row className="margin_0">
                    <Col
                        className="background_white col-sm-5 pad_65"
                    >
                        <h4 className="main_title main_b_color">{BoxModel.title}</h4>
                    </Col>
                    <Col
                        className="background_mask_blue col-sm-7 pad_65"
                    >
                        <div className="align-self-center">
                            <p className="main_text main_w_color">{BoxModel.text}</p>
                            <Link className="main_text main_w_color" to="/contact">{BoxModel.cta}</Link>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    )
}

export default BoxModule