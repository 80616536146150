import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo" 

import Hero from "../components/homepage/hero"
import BoxModule from "../components/homepage/boxModule"
import CardsModule from "../components/homepage/cardsModule"
import BoxModuleBlue from "../components/homepage/boxModuleBlue"
import ImgModule from "../components/homepage/imgModule"
import CTAModule from "../components/common/ctaModule"


const IndexPage = () => {
    return (
    <Layout>
        <SEO title="Jus-Pharma" />
        <Hero />
        <BoxModule />
        <CardsModule />
        <BoxModuleBlue />
        <ImgModule />
        <CTAModule 
            title="Connect with a Jus-Pharma team member today!"
            css='background_f'
        />
    </Layout>
    )
}

export default IndexPage